import React from 'react'
import { motion } from "framer-motion";
import { styles } from '../styles';
import MacBookCanvas from './canvas/MacBook';
import { heroText } from '../constants';

const Hero = () => {
  const formattedHeroText = heroText.replace(/\./g, '.\n');
  const formattedHeroTextWithLineBreaks = formattedHeroText.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));

  return (
    <section className='relative w-full h-screen mx-auto'>
      <div className={`${styles.paddingX} absolute inset-0 s:top-[60px] sm:top-[80px] top-[120px] 
        max-w-7xl mx-auto flex flex-row items-start gap-3`}>
        <div className='flex flex-col justify-center items-center mt-5'>
          <div className='w-5 h-5 rounded-full bg-[#915eff]' />
          <div className='w-1 sm:h-80 h-40 violet-gradient' />
        </div>
        <div className='sm:m-[5px]' >
          <h2 className={`${styles.heroHeadText} text-white`}>Hello, I'm <span className='text-[#915eff]'>Stanislav</span></h2>
          <h3 className={`${styles.heroSubText} text-white`}>
            {formattedHeroTextWithLineBreaks}
          </h3>
        </div>
      </div>
      <MacBookCanvas />
      <div className='absolute sm:bottom-10 bottom-32 w-full flex justify-center items-center'>
        <a href='#about'>
          <div className='w-[35px] h-[64px] 
              rounded-3xl border-4 border-secondary  
              flex justify-center items-start p-2' >
            <motion.div
              animate={{
                y: [0, 24, 0]
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: 'loop'
              }}
              className='w-3 h-3 rounded-full bg-secondary mb-1'
            />
          </div>
        </a>
      </div>
    </section>
  )
}

export default Hero