import React from 'react';
import {Tilt} from 'react-tilt';
import { motion } from 'framer-motion';

import { styles } from '../styles';
import { services } from '../constants';
import { fadeIn, textVariant } from '../utils/motion';
import SectionWrapper from './hoc';

const ServiceCard = ({index, title, icon}) => {
  return(
    <Tilt className='sm:w-[250px] w-full'>
      <motion.div
        variants={fadeIn("right","spring", 0.5 * index, 0.75)}
        className='w-full rounded-[20px] green-pink-gradient p-[1px] shadow-card'
      >
        <div 
          options={{
            max: 45,
            scale: 1,
            speed: 450,
          }}
          className='bg-tertiary 
            rounded-[20px] 
            min-h-[200px]
            py-5 px-12 
            min-g-[280px]
            flex
            justify-evenly
            items-center
            flex-col'
        >
          <img src={icon} alt={title} className='w-16 h-16 object-contain' />
          <h3 className='text-white text-[20px] font-bold text-center'>{title}</h3>
        </div>
      </motion.div>
      {/* {title} */}
    </Tilt>
  )
}

const About = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p className={styles.sectionSubText}>
          Introduction
        </p>
        <h2 className={styles.sectionHeadText}>
          Overview
        </h2>

      </motion.div>

      <motion.p variants={fadeIn('', '', 0.1, 1)}
        className='mt-4 text-secondary text-[17px] max-w-3xl leading-[30px]'
      >
          I focus on developing and supporting top-notch web applications that push boundaries.
          <br className='sm:block hidden'/>
          With   experience in the latest technologies and trends, I'm committed to providing innovative solutions to complex problems.
      </motion.p>
      <div className='mt-20 flex flex-wrap gap-10'>
        {services.map((el, i) => {
          return(
            <ServiceCard 
            key={el.title}
            index={i}
            {...el}
          />
          )
          
        })}
      </div>
    </>
  )
}

export default SectionWrapper(About, 'about');