import { ph } from "../assets";



const Footer = () => {
    return (
        <div className="w-full h-[70px] bg-black-100 text-white flex items-center rounded-[5px]">
            <div className="flex-[0.2] flex text-center items-center gap-3">
                <img className="ml-[4vw] h-[55px] w-[55px] rounded-full border-emerald-50 border-2" src={ph} alt='my_pic'/>
                Stanislav K.
            </div>
            <div className="flex-[1] text-center">All rights reserved<br/> © 2023 </div>
        </div>
    )
};

export default Footer;