import { motion } from 'framer-motion';
import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { styles } from '../styles';
import { EarthCanvas } from './canvas';
import SectionWrapper from './hoc';
import { slideIn } from '../utils/motion';
import Links from './Links';

const Contacts = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value })
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!form.name || !form.email || !form.message) {
      alert('Please fill all the form fields before sending.');
      return;
    }

    setLoading(true);
    emailjs.send('service_p6uqeqg', 'template_fse3lle', {
      from_name: form.name,
      to_name: 'Stan',
      from_email: form.email,
      to_email: 'Stanislav.kad@gmail.com',
      message: form.message,
    }, 'tHwFzL0GGoaWz3Ayb')
      .then(() => {
        setLoading(false);
        alert('Thank you for getting in touch, I will get back to you as soon as possible!');
        setForm({
          name: '',
          email: '',
          message: '',
        })
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
        alert('Something went wrong during sending email, please contact me directly. email: Stanislav.Kad@gmail.com.')
      });
  };

  return (
    <div className='xl:mt-12 xl:flex-row flex-col-reverse flex gap-10 overflow-hidden'>
      <motion.div
        variants={slideIn('left', 'tween', 0.2, 1)}
        className='flex-[0.75 bg-black-100 p-8 rounded-2xl'
      >
        <p className={styles.sectionSubText}>
          Get in touch
        </p>
        <p className={styles.sectionHeadText}>
          Contact me.
        </p>
        <Links />
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className='mt-12 flex flex-col gap-8'
        >
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>Your Name</span>
            <input
              type='text'
              name='name'
              value={form.name}
              onChange={handleChange}
              placeholder="What's your good name?"
              className='py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-1 border-none font-medium'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>Your email</span>
            <input
              type='email'
              name='email'
              value={form.email}
              onChange={handleChange}
              placeholder="What's your web address?"
              className='py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-1 border-none font-medium'
            />
          </label>
          <label className='flex flex-col'>
            <span className='text-white font-medium mb-4'>Your Message</span>
            <textarea
              rows={7}
              name='message'
              value={form.message}
              onChange={handleChange}
              placeholder='What you want to say?'
              className='py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-1 border-none font-medium'
            />
          </label>
          <button
            type='submit'
            className='
              bg-[#dfcdcd]
              hover:bg-gray-700 
              hover:text-white
              py-3 
              px-8
              w-fit
              text-[#294b7f] 
              font-bold 
              shadow-md 
              shadow-primary
              rounded-xl'
          >
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </motion.div>

      <motion.div
        variants={slideIn('right', 'tween', 0.2, 1)}
        className='xl:flex-1 xl:h-auto md:h-[550px] h-[350px]'
      >
        <EarthCanvas />
      </motion.div>
    </div>
  )
}

export default SectionWrapper(Contacts, 'contact');