import React from "react";
import lin from '../assets/lin.png';
import inst from '../assets/inst.png';
import git from '../assets/git.png';
import mail from '../assets/mail.png';


const Links = () => {
    return (
        <div className="flex items-center justify-center space-x-6">
            <a
                href="https://github.com/Stskdrv"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <img
                    src={git}
                    alt="git_icon"
                    className="w-6 h-6 cursor-pointer"
                />
            </a>
            <a
                href="https://www.instagram.com/stskdrv/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <img
                    src={inst}
                    alt="inst_icon"
                    className="w-6 h-6 cursor-pointer"
                />
            </a>
            <a
                href="https://www.linkedin.com/in/stanislavkadyrov/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <img
                    src={lin}
                    alt="in_icon"
                    className="w-6 h-6 cursor-pointer"
                />
            </a>
            <a
                href="mailto:stanislav.kad@gmail.com"
                rel="noopener noreferrer"
                className="icon-link"
            >
                <img
                    src={mail}
                    alt="mail_icon"
                    className="w-6 h-6 cursor-pointer"
                />
            </a>
        </div>
    );
};


export default Links;