import React from 'react';
import { BallCanvas } from './canvas';
import { technologies } from '../constants';
import SectionWrapper from './hoc';
import {v4} from 'uuid';


const Tech = () => {
  return (
    <div className='flex flew-row flex-wrap justify-center gap-10-'>
      {technologies.map((technology) => (
        <div className='w-28 h-28' key={v4()}>
          <BallCanvas icon={technology.icon} />
        </div>
      ))}
    </div>
  )
}

export default SectionWrapper(Tech, '');