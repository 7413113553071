import React from 'react';
import { qrcode } from '../assets';

const QrModal = ({ isOpen, toggleModal }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="modal-overlay absolute inset-0 bg-gray-700 opacity-75 " onClick={toggleModal}></div>

            <div className="modal-container flex bg-white w-96 p-4 rounded-lg z-50 flex-col">
                <div className='text-black bg-white w-90 p-4 text-center'>
                    Please scan qr code to launch my app on your smartphone<br/>(expo-go app required).<br/>
                    Server start time can be up to 5 minutes, please be patient and thank you for your interest.
                </div>
                <img src={qrcode} alt="Modal Content" className="w-full h-auto rounded-lg" />
            </div>
        </div>
    );
};

export default QrModal;