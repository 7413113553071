//import { motion } from 'framer-motion';
//import { fadeIn, textVariant } from '../utils/motion';
import React, { useState } from 'react';
import { Tilt } from 'react-tilt';
import { styles } from '../styles';
import { github, link } from '../assets';
import SectionWrapper from './hoc';
import { projects } from '../constants';
import QrModal from './QrModal';


const ProjectCard = ({ index, name, description, keyFeatures, logo, deployed_link, tags, image, source_code_link }) => {

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    // <motion.div
    //   variants={window.innerWidth < 400 ? null : fadeIn('up', 'spring', index * 0.5, 0.75)}
    // >
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        className='bg-tertiary p-5 rounded-2xl sm:w-[300px] md:w-[360px]'
      >
        <div className='relative w-full h-[230px]'>
          <img
            src={image}
            alt={name}
            className='w-full h-full object-contain rounded-3xl'
          />

          <div className='absolute inset-0 flex justify-end flex-col gap-2 m-3 card-img_hover'>
            {source_code_link &&
              <div
                onClick={() => window.open(source_code_link, '_balnk')}
                className='black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
              >
                <img
                  src={github}
                  alt='github_icon'
                  className='w-1/2 h-1/2 object-contain'
                />
              </div>
            }
            {
              deployed_link &&
              <div
                onClick={() => window.open(deployed_link, '_balnk')}
                className='black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
              >
                <img
                  src={link}
                  alt='link_icon'
                  className='w-1/2 h-1/2 object-contain'
                />

              </div>
            }
            {
              logo &&
              <div
                onClick={toggleModal}
                className='bg-gray-200 w-10 h-10 rounded-full flex justify-center items-center cursor-pointer'
              >
                <img
                  src={logo}
                  alt='github_icon'
                  className='w-1/2 h-1/2 object-contain'
                />

              </div>
            }
          </div>
          <div className="flex flex-col items-center justify-center min-h-screen p-4">
            <QrModal isOpen={modalOpen} toggleModal={toggleModal} />
          </div>
        </div>

        <div className='mt-5'>
          <h3 className='text-white font-bild text-[24px]'>{name}</h3>
          <p className='mt-2 text-secondaty text-[14px]'>{description}</p>
          <p className='mt-2 text-secondaty text-[14px]'>{
            keyFeatures?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          }</p>
          <div className='mt-4 flex flex-wrap gap-2'>
            {tags.map((tag) => (
              <p key={tag.name} className={`text-[14px] ${tag.color}`}>
                #{tag.name}
              </p>
            ))}
          </div>
        </div>

      </Tilt>
    // </motion.div >
  )
};

const Works = () => {

  return (
      // <motion.div
      //   variants={window.innerWidth < 400 ? null : textVariant()}
      // >
      <>
        <p className={styles.sectionSubText}>
          My prsonal projects.
        </p>
        <h2 className={styles.sectionHeadText}>
          Please take a look at my projects.
        </h2>
        <div className='w-full flex'>
          {/* <motion.p
            variants={window.innerWidth < 450 ? null : fadeIn('', '', 0.1, 1)}
            className='mt-3 text-secondary text-[17px] max-w-6xl leading-[30px]'
          > */}
            My main contribution as a developer lies in the production projects at Epam, which I have detailed in my work experience. However, during my free time, I enjoy exploring new technologies that are not represented in my production projects. I believe the primary purpose of learning is to apply this knowledge in practice, which is exactly what I do.<br /> In this section, you can get acquainted with my personal projects. While these are not all of them, they are the most interesting ones that I would like to share information about.<br /> Since they are deployed on hosting, sometimes it may take some time for the server to start and the project to compile after a long pause. Please be patient, it might take 3-5 minutes. Also, some of my repo is private, but please feel free to ask and I can easily change the visibility of it. Thank you for your interest in my projects!
          {/* </motion.p> */}
        </div>
        <div className='mt-20 flex flex-wrap gap-7 justify-center'>
          {projects.map((project, i) => (
            <ProjectCard
              key={`project-${i}`}
              index={i}
              {...project}
            />
          ))}
        </div>
        </>

      // </motion.div>
  )
}

export default SectionWrapper(Works, 'projects');