import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Contacts from './components/Contacts';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import { About, Experience, Feedbacks, StarsCanvas, Tech } from './components';
import Works from './components/Works';
import { useState } from 'react';
import ResumeModal from './components/ResumeModal';
import Footer from './components/Footer';

const Secondary = () => {
  const [isResumeOpen, setIsResumeOpen] = useState(false);

  const toggleResumeModal = () => setIsResumeOpen(!isResumeOpen);

  return (
    <BrowserRouter>
      <div className='relative z-0 bg-primary'>
        <div className='bg-hero-pattern bg-cover bg-no-repeat bg-center'>
          <Navbar toggleResumeModal={toggleResumeModal} />
          <Hero />
        </div>
        <About />
        <Experience />
        <Tech />
        <Works />
        <Feedbacks />
        <div className='relative z-0'>
          <Contacts />
          <StarsCanvas />
        </div>
        <Footer />
        <ResumeModal isOpen={isResumeOpen} toggleResumeModal={toggleResumeModal} />
      </div>
    </BrowserRouter>
  )
}

function App() {
  return <Secondary />;
}

export default App;
