
import {
  mustache,
  javascript,
  typescript,
  html,
  css,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  git,
  threeicon,
  figma,
  react,
  nodeicon,
  js,
  interview,
  mentor,
  aws,
  epam,
  mazur,
  liza,
  vlad,
  expologo,
  pws,
  ang,
  postio,
  weatherLook,
  mean,
  dash,
  blog,
  fl,
  el,
  brs,
  next,
  chat,
} from "../assets/";

export const navLinks = [
  {
    id: "about",
    title: "About",
  },
  {
    id: "work",
    title: "Work",
  },
  {
    id: "projects",
    title: "Projects",
  },
  {
    id: "feedbacks",
    title: "Feedbacks",
  },
  {
    id: "contact",
    title: "Contact",
  },
];

const services = [
  {
    title: "Senior Software Engineer",
    icon: mustache,
  },
  {
    title: "JS / TS Developer",
    icon: js,
  },
  {
    title: "React / \nReact Native Developer",
    icon: react,
  },
  {
    title: "Node.js Developer",
    icon: nodeicon,
  },
  {
    title: "Next.js Developer",
    icon: next,
  },
  {
    title: "Cloud Practitioner",
    icon: aws,
  },
  {
    title: "Certified Interviewer",
    icon: interview,
  },
  {
    title: "Mentor",
    icon: mentor,
  },
];

const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Three JS",
    icon: threeicon,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "figma",
    icon: figma,
  },
  {
    name: "angular",
    icon: ang,
  },
];

const experiences = [
  {
    title: "Senior Software Engineer(project#2)",
    company_name: "Epam Systems",
    icon: epam,
    iconBg: "#E6DEDD",
    date: "Aug 2023 - Now",
    description: "In this project, a search engine powered by AI based on internal LLM's is being developed for the client's internal needs. I joined the team in the middle of the MVP development stage to strengthen the team.",
    points: [
      "Playing custom lead role in a front-end team;",
      "Refactoring approximately 40% of the code;",
      "Improving the project architecture by introducing SOLID principals;",
      "In communication with a business analyst and designer, I made adjustments to the interface of elements;",
      "I have created sub-application for on-line editing JSON-based nested tables templates;",
      "Corrected the interaction scheme between the client and the server, bringing it closer to the RESTful API.",
    ],
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "Vite",
        color: "orange-text-gradient",
      },
      {
        name: "React",
        color: "green-text-gradient",
      },
      {
        name: "Redux-Toolkit",
        color: "pink-text-gradient",
      },
      {
        name: "RTKQuery",
        color: "green-text-gradient",
      },
      {
        name: "shadcn/ui",
        color: "pink-text-gradient",
      },
      {
        name: "Tailwind_CSS",
        color: "blue-text-gradient",
      },
      {
        name: "react-hook-form",
        color: "green-text-gradient",
      },
      {
        name: "axious",
        color: "pink-text-gradient",
      },
      {
        name: "Zod",
        color: "blue-text-gradient",
      },
    ],
  },
  {
    title: "Senior Software Engineer(project#1)",
    company_name: "Epam Systems",
    icon: epam,
    iconBg: "#E6DEDD",
    date: "Jan 2022 - Aug 2023",
    description: "Lifescience project, it is about to create powerful table-oriented application for biologics research. Key contributions: ",
    points: [
      "Performance optimization - reduced the number of re-renderers of the table due to the correct usage of memoization, a decrease of 3 prerenders;",
      "Rewrote 20% of unit tests by removing the Enzyme and adding RTL;",
      "I have increased the coverage of e2e testing by more than 30% with the help of new integration tests;",
      "I have  added the Jira issue collector feature integrated into our application, direct feedback from users;",
      "I have created sub-application for on-line editing JSON-based nested tables templates.",
      "Performed code review;",
      "Performed mentoring;",
    ],
    tags: [
      {
        name: "JavaScript",
        color: "blue-text-gradient",
      },
      {
        name: "React",
        color: "green-text-gradient",
      },
      {
        name: "Redux-Toolkit",
        color: "pink-text-gradient",
      },
      {
        name: "Cypress",
        color: "orange-text-gradient",
      },
      {
        name: "Cucumber",
        color: "pink-text-gradient",
      },
      {
        name: "RTL",
        color: "blue-text-gradient",
      },
    ],
  },
  {
    title: "Software Engineer (project#1)",
    company_name: "Epam Systems",
    icon: epam,
    iconBg: "#E6DEDD",
    date: "Dec 2021 - Jan 2023",
    description: " Lifescience project, was about rebuild legacy application(wrote with marionette.js) to React. Key contributions: ",
    points: [
      "I have refactored approximately 40% of all new application components as part of the migration from Recoil state management to Redux state management;",
      "Rewrote all affected unit and integration tests in the application;",
      "Created an application page for editing test tube kits (the application is used in the medicine development process) including layout, logical part, Redux and tests;",
      "I have added code documentation;",
      "Performed mentoring;",
    ],
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "React",
        color: "green-text-gradient",
      },
      {
        name: "Redux-Toolkit",
        color: "pink-text-gradient",
      },
      {
        name: "StoryBook",
        color: "orange-text-gradient",
      },
      {
        name: "StyledComponents",
        color: "pink-text-gradient",
      },
      {
        name: "MUi",
        color: "orange-text-gradient",
      },
    ],
  },
  {
    title: "Software Engineer (project#2)",
    company_name: "Epam Systems",
    icon: epam,
    iconBg: "#E6DEDD",
    date: "Dec 2021 - Jan 2023",
    description: "  I participated as a full-stack developer. It was a CRUD application aimed at creating a set of example solutions for common tasks that developers encounter while working on a web application, such as client-side and server-side authentication, working with various data types/files, interacting with databases, AWS, Azure and so on! It was like a collection of blueprints. Key contributions: ",
    points: [
      "I have created base CRUD application from the scratch;",
      "Created a presentation with a pitch of pros and cons of various ORM systems and influenced the choice of the final technology;",
      "Added authentication on the client side using Azure AD;",
      "Integrated some of AWS services(s3 bucket, RDS, EC2, S3 object Lambda);",
    ],
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "Node.js",
        color: "orange-text-gradient",
      },
      {
        name: "React",
        color: "green-text-gradient",
      },
      {
        name: "AWS",
        color: "orange-text-gradient",
      },
      {
        name: "Azure",
        color: "pink-text-gradient",
      },
      {
        name: "Nest.js",
        color: "green-text-gradient",
      },
      {
        name: "PostgreSQL",
        color: "pink-text-gradient",
      },
      {
        name: "MUi",
        color: "blue-text-gradient",
      },
      {
        name: "Cypress",
        color: "orange-text-gradient",
      }
    ],
  },
  {
    title: "Junior Software Engineer",
    company_name: "Epam Systems",
    icon: epam,
    iconBg: "#E6DEDD",
    date: "Dec 2020 - Dec 2021",
    description: "  Parking project. The application made it easy for both users and business center administrators to manage parking reservations. Key contributions: ",
    points: [
      "Improved performance when querying the backend by 0.6 seconds, by optimizing the amount of data received (with a teamwork backend developer), add lazy loading to increase app responding speed;",
      "I wrote the missing unit tests for about 5-10% of the components in the application;",
      "I have added about 20 new components of varying complexity;",
      "Participating in code reviews and providing constructive feedback to other developers.",
    ],
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "React",
        color: "green-text-gradient",
      },
      {
        name: "Redux-Toolkit",
        color: "pink-text-gradient",
      },
      {
        name: "StoryBook",
        color: "orange-text-gradient",
      },
      {
        name: "StyledComponents",
        color: "pink-text-gradient",
      },
      {
        name: "MUi",
        color: "orange-text-gradient",
      },
    ],
  },
  {
    title: "Freelance Web Developer",
    company_name: "Freelance",
    icon: fl,
    iconBg: "#E6DEDD",
    date: "July 2019 - Oct 2020",
    points: [
      "Developed and delivered 3 professional websites for diverse companies, leveraging my expertise in web development;",
      "Participated in the development of web applications as part of the Frontend team of a local startup as part of a business incubator."
    ],
  },
  {
    title: "Web Master",
    company_name: "BlokRemStroy",
    icon: brs,
    iconBg: "#ede9e1",
    date: "May 2019 - Oct 2020",
    points: [
      "Provided website support and maintenance services for business, ensuring seamless functionality and resolving technical issues promptly;",
      "Integrated the 1C system and web interface for receiving and processing customer requests and selling components."
    ],
    tags: [],
  },
  {
    title: "Chief Engineer",
    company_name: "EliteProject",
    icon: el,
    iconBg: "#f9f9fb",
    date: "Aug 2015 - July 2019",
    points: [
      "Headed the engineering department of 10 engineers for developing projects for industrial, residential and storm water treatment facilities. Our department designed turnkey solutions, starting from engineering surveys and ending with design, working and construction documentation;",
      "Due to the large volume of drawings, I was involved in the development of a project for automating the process of generating CAD drawings using software written in a combination of 1C and AutoDesk API. And become interested in IT and Programming",
    ],
    tags: [],
  },
];

const testimonials = [
  {
    testimonial:
      "Stanislav made an impression of mature software engineer. He proved quite solid technical skills, experience which is wide enough for a senior developer, and showed good attitude, ability to elaborate and speculate on complex topics, showed reasonable decision-making skill. He showed understanding of development processes and ability to navigate in situational cases, was able to provide examples.",
    name: "Vitalii Mazur",
    designation: "Lead Software Engineer",
    company: "Epam Systems",
    image: mazur,
  },
  {
    testimonial:
      "I started working with Stanislav from the very beginning. He joined Epam as a junior developer, and so far has been developing impressively rapidly. He is constantly learning and developing skills even if there are no such on his project. He had experience with React on the project, but he passed the AWS course himself and studied Angular. Besides all this, it's vital to notice that Stanislav is a very loyal, self-criticized, and polite employee. With such passion and dedication he has, Stanislav will definitely achieve even more impressive results.",
    name: "Elizabeth Anatskaya",
    designation: "Lead Software Engineer",
    company: "Epam Systems",
    image: liza,
  },
  {
    testimonial:
      "I have worked with Stanislav for a long time on different projects. When we've met for the first time he was junior developer with zero production experience. It was challenging task for him to learn how to code. But he listened carrefully everyone advices, and worked hard. Now Stanislav could easylly solve all of the problems, he have great and relevant experience in front-end development. Moreover he continue his learning path, compleeting node.js and aws courses.",
    name: "Vladislav Potapov",
    designation: "Lead Software Engineer",
    company: "Epam Systems",
    image: vlad,
  },
];

const shapes = [
  "M254 286.11a50 50 0 0050-50H204a50 50 0 0050 50z",
  "M255.5 271a20 20 0 10-20-20 20 20 0 0020 20zm0 30a50 50 0 10-50-50 50 50 0 0050 50z",
  "M248.8 202.17a8 8 0 019.4 0l40.6 29.5a8 8 0 012.9 8.94l-15.5 47.73a8 8 0 01-7.61 5.52h-50.18a8 8 0 01-7.61-5.52l-15.5-47.73a8 8 0 012.9-8.94z",
  "M307.5 250a50 50 0 11-50-50 50 50 0 0150 50",
  "M248.08 204.07a11.91 11.91 0 0116.84 0l30.59 30.59a11.91 11.91 0 11-16.85 16.85l-10.25-10.25v47.41a11.91 11.91 0 11-23.82 0v-47.41l-10.25 10.25a11.91 11.91 0 01-16.85-16.85z",
  "M234 237a22.5 22.5 0 0045 0h27.5a50 50 0 01-100 0z",
  "M258 202.5a12 12 0 00-12 12v26h-26a12 12 0 000 24h26v26a12 12 0 0024 0v-26h26a12 12 0 000-24h-26v-26a12 12 0 00-12-12z"
];

const heroText = "As a passionate Senior Software Engineer, I love building innovative solutions and continuously honing my craft. From developing various web applications to creating engaging online courses, I am dedicated to advancing technology and sharing knowledge with the developer community, also I thrive as a collaborative team member in Agile and Scrum environments"

const projects = [
  {
    name: "CRM System",
    description:
      "Full-stack CRM system developed using the MEAN stack (MongoDB, Express.js, Angular, Node.js). \n \n It facilitates goods management, category organization, order creation, and order tracking. The system offers user authentication, a UI component library, image uploading, and analytics.",
    keyFeatures:
      "Key featueres:\n Server: Authorization, CRUD operations with entities of varying complexity, working with a database, etc.;\n Client: Using the UI Component Library, Navigation between a large number of pages, Uploading images, Showing analytics in charts",
    tags: [
      {
        name: "MongoDB",
        color: "blue-text-gradient",
      },
      {
        name: "Express",
        color: "green-text-gradient",
      },
      {
        name: "Angular",
        color: "pink-text-gradient",
      },
      {
        name: "Node.js",
        color: "green-text-gradient",
      },
      {
        name: "JWT",
        color: "pink-text-gradient",
      },
      {
        name: "Materialize css",
        color: "blue-text-gradient",
      },
    ],
    image: mean,
    source_code_link: "https://github.com/Stskdrv/meanapp",
    deployed_link: "https://mean-cafe.onrender.com/",
  },
  {
    name: "WeatherLook app",
    description:
      "I developed a full-stack mobile application using the ME(RN)N(MongoDB, Express.js, React Native, Node.js). \nThe primary objective of the app is to provide users with current weather data and weather forecasts. Additionally, it allows users to capture and save pictures of themselves along with descriptions of their attire in the given weather, enabling them to refer to it later. I created application from scratch, and the process was a highly enriching experience, involving tasks such as architecture design and implementation of the full stack for the mobile app.",
    keyFeatures:
      "Key featueres:\n Server: Authorization, CRUD operations with entities of varying complexity, working with a database, implementing interceptors, middlewares, etc.;\n Client: Form validation, Navigation between a large number of screens, Fetching from different APIs, usage Native base UI library",
    tags: [
      {
        name: "MongoDB",
        color: "blue-text-gradient",
      },
      {
        name: "Express",
        color: "green-text-gradient",
      },
      {
        name: "React-native",
        color: "pink-text-gradient",
      },
      {
        name: "Node.js",
        color: "green-text-gradient",
      },
      {
        name: "Native-base",
        color: "pink-text-gradient",
      },
      {
        name: "Expo",
        color: "blue-text-gradient",
      },
      {
        name: "expo-camera",
        color: "green-text-gradient",
      },
      {
        name: "formik",
        color: "blue-text-gradient",
      },
      {
        name: "Redux-toolkit",
        color: "pink-text-gradient",
      },
      {
        name: "axios",
        color: "green-text-gradient",
      },
      {
        name: "expo-secure-store",
        color: "pink-text-gradient",
      },
    ],
    image: weatherLook,
    logo: expologo,
    source_code_link: "",
  },
  {
    name: "My personal web site",
    description:
      "I have developed this website so that anyone can always find up-to-date information about my career and experience, and easily and quickly get in touch with me through a convenient contact form if needed. \nIt was also an excellent opportunity for me to practice working with technologies that I hadn't used before. \nSo, dear website visitors, I invite anyone who wants to collaborate to write to me!",
    keyFeatures:
      "Key featueres:\n Client: Animations, Nice 3d objects, Responcive design, another one Ui library. Deployed with AWS CloudFront",
    tags: [
      {
        name: "Three.js",
        color: "blue-text-gradient",
      },
      {
        name: "Tailwind-css",
        color: "green-text-gradient",
      },
      {
        name: "framer-motion",
        color: "pink-text-gradient",
      },
      {
        name: "react-tilt",
        color: "green-text-gradient",
      },
      {
        name: "GSAP",
        color: "blue-text-gradient",
      },
      {
        name: "AWS",
        color: "blue-text-gradient",
      },
      {
        name: "CloudFront",
        color: "blue-text-gradient",
      },
    ],
    image: pws,
    // source_code_link: "https://github.com/",
  },
  {
    name: "Postio app",
    description:
      "Welcome to Postio – one of my latest project designed to bring people closer through the power of short and engaging text posts. App powered with TypeScript, Next.js 13. I created Postio with the goal of making it easier than ever for you to connect with friends and express yourself through concise text updates. Users can create an account using the clerk form, then create posts, communities, participate in discussions, comment on other people's posts, and so on. Test creds: name: 'test'; pass: 'testpass54321'",
    keyFeatures:
      "Key featueres:\n Auth flow with clerk. Operations with posts,communities and oser users. Direct work with DB. Deployed with Vercel",
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "Next.js 13",
        color: "pink-text-gradient",
      },
      {
        name: "Tailwind-css",
        color: "green-text-gradient",
      },
      {
        name: "shadcn/ui",
        color: "pink-text-gradient",
      },
      {
        name: "Clerk",
        color: "green-text-gradient",
      },
      {
        name: "Zod",
        color: "blue-text-gradient",
      },
      {
        name: "Vercel",
        color: "green-text-gradient",
      },
    ],
    image: postio,
    // source_code_link: "https://github.com/",
    deployed_link: "https://p-f.vercel.app/",
  },
  {
    name: "My Personal Blog",
    description:
      "I present to your attention my personal blog platform. This is a full-stack application built on the TypeScript + Next.js 13 framework. It includes authentication functionality, post creation, category navigation, post pagination, and the ability to add comments after logging in through a Google account.",
    keyFeatures:
      "Key featueres:\n Auth flow with NextAuth. I used Prisma ORM with Mongo DB. Storing image fith Fitebase. Google Auth provider. Google Analytics metrics tracker. Deployed with AWS Amplify",
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "Next.js 13",
        color: "pink-text-gradient",
      },
      {
        name: "Prisma",
        color: "green-text-gradient",
      },
      {
        name: "Firebase",
        color: "pink-text-gradient",
      },
      {
        name: "react-quill",
        color: "green-text-gradient",
      },
      {
        name: "NextAuth",
        color: "blue-text-gradient",
      },
      {
        name: "AWS Amplify",
        color: "green-text-gradient",
      },
    ],
    image: blog,
    // source_code_link: "https://github.com/",
    deployed_link: "https://stanwebdev.blog/",
  },
  {
    name: "Dashboard",
    description:
      "The purpose of creating the application was to demonstrate proficiency in working with the powerful and user-friendly Material UI library.Since its primary objective is to showcase skills in working with the frontend part, this is the client-side of the application, and its interactivity is limited to the ability to switch between light and dark themes. The displayed data is implemented using a mock approach. I chose not to add a server-side part and not turn it into fullstack as I did not see the specific need for it in this case.",
    keyFeatures:
      "Key featueres:\n Custom MUi theme switcher and color palette. MUi DataGrid tables. Informative charts visualisation. Interactive Calendar. Form validation.",
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "React",
        color: "pink-text-gradient",
      },
      {
        name: "Nivo",
        color: "green-text-gradient",
      },
      {
        name: "Material-Ui",
        color: "blue-text-gradient",
      },
      {
        name: "fullcalendar",
        color: "pink-text-gradient",
      },
      {
        name: "Formik",
        color: "green-text-gradient",
      },
      {
        name: "Yup",
        color: "blue-text-gradient",
      },
      {
        name: "React Pro Sidebar",
        color: "green-text-gradient",
      },
    ],
    image: dash,
    source_code_link: "https://github.com/Stskdrv/dp-a/",
    deployed_link: "https://dp-a.vercel.app",
  },
  {
    name: "Real-time chat app",
    description:
      "Introducing my real-time chat application project, showcasing my proficiency in WebSocket and Socket.io integration. This full-stack application is developed using TypeScript and Node.js, leveraging the power of Socket.io for real-time communication. It includes authentication functionality, real-time messaging, user status indicators, message history. P.S. While the project demonstrates proficiency in real-time app development, there is still room for improvements such as code refactoring, design enhancements, and the addition of new features. However, I have chosen to prioritize moving forward with other projects rather than investing further time in this project's development.",
    keyFeatures:
      "Key featueres:\n WebSocket integration: Real-time messaging capabilities powered by Socket.io, ensuring instant communication between users. Authentication flow: Implemented using JWT authentication for secure access to messaging features. User status indicators: Display online/offline status of users for context in messaging interactions.",
    tags: [
      {
        name: "TypeScript",
        color: "blue-text-gradient",
      },
      {
        name: "React",
        color: "pink-text-gradient",
      },
      {
        name: "Node",
        color: "green-text-gradient",
      },
      {
        name: "TailwindCSS",
        color: "blue-text-gradient",
      },
      {
        name: "socket.io",
        color: "pink-text-gradient",
      },
      {
        name: "Formik",
        color: "green-text-gradient",
      },
      {
        name: "Yup",
        color: "blue-text-gradient",
      },
      {
        name: "socket.io-client",
        color: "green-text-gradient",
      },
      {
        name: "vite",
        color: "green-text-gradient",
      },
    ],
    image: chat,
    source_code_link: "https://github.com/Stskdrv/chat",
    deployed_link: "https://vs-depl-chat-cl.vercel.app/",
  },
];

export { services, technologies, experiences, testimonials, projects, shapes, heroText };