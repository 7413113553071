import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { motion } from 'framer-motion';

import 'react-vertical-timeline-component/style.min.css';

import { styles } from '../styles';

import { experiences } from '../constants/index';
import SectionWrapper from './hoc/SectionWrapper';
import { textVariant } from '../utils/motion';

const ExperienceCard = ({ ex }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{ background: '#1d1836', color: '#fff' }}
      contentArrowStyle={{ boardRight: '7px solid #232631' }}
      date={ex.date}
      iconStyle={{ background: ex.iconBg }}
      icon={
        <div className='flex justify-center items-center w-full h-full'>
          <img
            src={ex.icon}
            alt={ex.company_name}
            className='w-[60%] h-[60%] object-contain'
          />
        </div>
      }
    >
      <div className={`mb-3 pb-2 ${!!ex.tags?.length && 'border-b-[1px] border-gray-200/25'}`}>
        <h3 className='text-white text-[22px] font-bold'>{ex.title}</h3>
        <p className='text-secondary text-[16px] font-semibold mt-0'>
          {ex.company_name}
        </p>
        <p className='text-white text-[16px] font-semibold mt-0'>
          {ex.description}
        </p>
        <ul className='mt-5 list-disc ml-5 space-y-2'>
          {ex.points.map((el, i) => (
            <li
              key={`experience-point-${i}`}
              className='text-white-100 text=[14px] pl-1 tracking-wider'
            >
              {el}
            </li>
          ))}
        </ul>
      </div>
      {!!ex.tags?.length && 'Tech stack:' }
      <div className=' flex flex-wrap gap-2'>
            {!!ex.tags?.length && ex.tags?.map((tag) => (
              <p key={tag.name} className={`text-[14px] ${tag.color}`}>
                #{tag.name}
              </p>
            ))}
          </div>
    </VerticalTimelineElement>
  )
};


const Experience = () => {
  return (
    <>
      <motion.div
        variants={window.innerWidth < 450 ? null : () => textVariant()}
      >
        <p className={styles.sectionSubText}>
          What I have done so far
        </p>
        <h2 className={styles.sectionHeadText}>
          Work experience
        </h2>


      </motion.div>

      <div className='mt-20 flex flex-col'>
        <VerticalTimeline>
          {experiences.map((ex, i) => {
            return <ExperienceCard key={i} ex={ex} />
          })}
        </VerticalTimeline>
      </div>
    </>
  )
}

export default SectionWrapper(Experience, 'work');