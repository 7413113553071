import { motion } from 'framer-motion';
import React from 'react';
import { styles } from '../styles';
import SectionWrapper from './hoc';
import { fadeIn, textVariant } from '../utils/motion';
import { testimonials } from '../constants';


const FeedbackCard = ({index, testimonial, name, designation, company, image}) => (
  <motion.div
    variants={window.innerWidth < 450 ? null : fadeIn('', 'spring', index * 0.5, 0.75)}
    className='bg-black-200 p-10 rounded-3xl sm:w-[250px] md:w-[320px] w-full'
  >
    <p className='text-white font-black text-[48px]'>
      "
    </p>
    <div className='mt-1'>
      <p className='text-white tracking-wider min-h-[680px]  text-[18px]'>{testimonial}</p>
      <div className='mt-7 flex justify-between items-center gap-1'>
        <div className='flex-1 flex flex-col'>
          <p className='text-white font-medium text-[16px]'>
            <span
              className='blue-text-gradient'
            >
              @
            </span> 
            {name}
          </p>
          <p
            className='mt-1 text-secondary text-[12px]'
          > 
            {designation} at {company}
          </p>
        </div>
        <img 
          src={image}
          alt={`feedback by ${name}`}
          className='w-10 h-10 rounded-full object-cover'
        />
      </div>
    </div>
  </motion.div>
)

const Feedbacks = () => {
  return (
    <div className='mt-12 bg-black-100 rounded-[20px]'>
      <div 
        className={`${styles.padding} 
        bg-tertiary min-h-[300px] rounded-[20px]`}
      >
        <motion.div variants={window.innerWidth < 450 ? null : textVariant()}>
          <p 
            className={styles.sectionSubText}> 
            What others say
          </p>
          <p 
            className={styles.sectionHeadText}
          > 
            Feedbacks.
          </p>
        </motion.div>
        <div className={`${styles.paddingX/3}-mt-20 pb-14 flex flex-wrap gap-7`}>
          {testimonials.map((testimonial, i) => (
            <FeedbackCard 
              key={testimonial.name}
              index={i}
              {...testimonial}
            />
          ))}

        </div>
      </div>
    </div>
  )
}

export default SectionWrapper(Feedbacks, 'feedbacks');