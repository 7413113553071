import React, { useEffect, useRef } from 'react';
import gsap from "gsap";
import { shapes } from '../constants';


const HoverButton = ({toggleResumeModal}) => {
    const containerRef = useRef(null);
    const svgRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const svg = svgRef.current;
        const numberOfShapes = 10;
        const animatedShapes = [];

        const handleMouseEnter = (e) => {
            for (let i = 0; i < numberOfShapes; i++) {
                const newElement = document.createElementNS(
                    "http://www.w3.org/2000/svg",
                    "path"
                );
                newElement.setAttribute("d", gsap.utils.random(shapes));
                newElement.style.fill = gsap.utils.random([
                    "#a6a2be",
                    "#915eff",
                    "#00eed3",
                    "#856181"
                ]);
                svg.appendChild(newElement);
                animatedShapes.push(newElement);
            }

            gsap.set(animatedShapes, {
                transformOrigin: "center",
                scale: "random(0.4, 0.8)"
            });

            gsap.to(animatedShapes, {
                keyframes: [
                    {
                        rotate: "random(180, -180)",
                        x: "random([-150, -100, -200, 200, 100, 150])",
                        y: "random([-150, -100, -200, 200, 100, 150])",
                        ease: "expo.out",
                        duration: 4,
                        stagger: {
                            amount: 0.1
                        }
                    },
                    { opacity: 0, delay: -3, onComplete: removeShapes }
                ]
            });
        };

        const removeShapes = () => {
            animatedShapes.forEach((shape) => {
                if (svg.contains(shape)) {
                    svg.removeChild(shape);
                }
            });
        };

        container.addEventListener("mouseenter", handleMouseEnter);

        return () => {
            container.removeEventListener("mouseenter", handleMouseEnter);
        };
    }, []);

    return (
        <div className='s:hidden sm:block absolute translate-x-[180px] translate-y-[-2px]'>
            <div onClick={toggleResumeModal} className="w-[200px] h-[150px] anim-explode-container inline-block relative" ref={containerRef}>
                <a href='#1' className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[8px] font-bold cursor-pointer
                    text-secondary text-[18px] border-1 hover:border-2 hover:text-white border-[#915eff] rounded-lg bg-transparent px-1 transition-all duration-100' >
                    Resume
                </a>
                <svg className="anim-explode w-[100%] h-[100%]" ref={svgRef} viewBox="0 0 500 500" />
            </div>
        </div>
    );
};

export default HoverButton;