import React from 'react';

const ResumeModal = ({ isOpen, toggleResumeModal }) => {
    if (!isOpen) return null;

    const handleDownload = async () => {
        const link = document.createElement('a');
        link.href = '/cv.pdf';
        link.download = 'Stanislav_Kadyrov_cv.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="modal-overlay absolute inset-0 bg-gray-700 opacity-75 " onClick={toggleResumeModal}></div>

            <div className="modal-container flex bg-white w-[70vh] p-4 rounded-lg z-50 flex-col">
                <div className='flex justify-between'> 
                    <div className='text-black bg-white p-3 text-center '>
                        Resume
                    </div>
                    <button 
                    onClick={toggleResumeModal} 
                    className="bg-white 
                        hover:bg-gray-50 
                        text-gray-500 
                        font-semibold 
                        border 
                        border-gray-400 
                        rounded 
                        shadow
                        w-[60px]
                        h-[40px]
                        text-center
                        self-end
                        "> 
                    Close
                </button>
                </div>
                

                <iframe
                    className='mt-10 rounded-lg'
                    title="Resume PDF"
                    src='/cv.pdf'
                    style={{ width: '100%', height: '60vh' }}
                 >
                 </iframe>
                <button 
                    onClick={handleDownload} 
                    className="bg-white 
                        hover:bg-gray-100 
                        text-gray-800 
                        font-semibold 
                        py-2 
                        px-4 
                        mt-10
                        border 
                        border-gray-400 
                        rounded 
                        shadow
                        self-center
                        w-[150px]
                        "> 
                    Download
                </button>
            </div>
        </div>
    );
};

export default ResumeModal;